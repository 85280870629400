import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";

import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "./material/material.module";
import { SharedModule } from "./shared/shared.module";
import { JwtModule } from "@auth0/angular-jwt";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";

import { SpinnerComponent } from "./shared/spinner.component";
import { FullComponent } from "./layouts/full/full.component";
import { HeaderComponent } from "./layouts/full/header/header.component";
import { SidebarComponent } from "./layouts/full/sidebar/sidebar.component";
import { AuthGuard } from "./guards/auth.guard";
import { AuthService } from "./services/auth.service";
import { ClienteService } from "./services/cliente.service";
import { FuncionarioService } from "./services/funcionario.service";

import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import localePtExtra from "@angular/common/locales/extra/pt";

import { MomentDateModule } from "@angular/material-moment-adapter";
import { NgxMaskModule } from "ngx-mask";
import { AdminGuard } from "./guards/admin.guard";
import { RelatoriosService } from "./services/relatorios.service";
import { MatButtonModule } from "@angular/material";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
};

registerLocaleData(localePt, "pt", localePtExtra);

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    HeaderComponent,
    SidebarComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MomentDateModule,
    NgxMaskModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["https://gr.keymax.com.br:3001", "localhost:3001"],
        // blacklistedRoutes: ['localhost:3000/auth', 'localhost:4200/login'],
        // throwNoTokenError: false
      },
    }),

    PerfectScrollbarModule,
    FlexLayoutModule,
    MaterialModule,
    SharedModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    // { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: "pt" },
    AuthGuard,
    AdminGuard,
    AuthService,
    ClienteService,
    FuncionarioService,
    RelatoriosService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
