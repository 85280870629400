import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(protected router: Router, protected authService: AuthService) {}

  canActivate() {
    console.log(this.router.url);
    if (this.router.url.endsWith("terms-and-conditions")) {
      return true;
    } else if (!this.authService.isLoggedIn()) {
      console.log("PODE PASSAR");
      return true;
    } else {
      this.router.navigate(["/login"]);
      console.log("YOU SHALL NOT PASS");
      return false;
    }
  }
}
