import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ClienteDto } from "../dto/cliente.dto";

const URL_CLIENTES: string = "https://gr.keymax.com.br:3001/cliente";
const URL_TODOS_CLIENTES: string =
  "https://gr.keymax.com.br:3001/cliente/todos";
const URL_NOVO_CLIENTE: string =
  "https://gr.keymax.com.br:3001/cliente/novoCliente";

// const URL_CLIENTES: string = 'http://localhost:3000/cliente';
// const URL_TODOS_CLIENTES: string = 'http://localhost:3000/cliente/todos';
// const URL_NOVO_CLIENTE: string = 'http://localhost:3000/cliente/novoCliente';

@Injectable({
  providedIn: "root",
})
export class ClienteService {
  constructor(private http: HttpClient) {}

  getClientes(pageIndex, pageSize) {
    return this.http.get<any>(
      `${URL_CLIENTES}?page=${pageIndex}&size${pageSize}`
    );
  }

  getAllClientes() {
    return this.http.get<any>(URL_TODOS_CLIENTES);
  }

  getCliente(idCliente: number) {
    return this.http.get<any>(`${URL_CLIENTES}/${idCliente}`);
  }

  createCliente(dadosCliente: ClienteDto) {
    return this.http.post<any>(URL_NOVO_CLIENTE, dadosCliente);
  }

  updateCliente(dadosCliente: ClienteDto) {
    return this.http.put<any>(URL_CLIENTES, dadosCliente);
  }
}
