import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}
export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  tipo?: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'clientes',
    name: 'Clientes',
    type: 'link',
    icon: 'business',
    permissao: 'admin'
  },
  {
    state: 'funcionarios',
    name: 'Funcionários',
    type: 'link',
    icon: 'assignment_ind',
    permissao: 'admin'
  },
  {
    state: 'usuarios',
    name: 'Usuários',
    type: 'link',
    icon: 'supervisor_account',
    permissao: 'admin'
  },
  {
    state: 'relatorios',
    name: 'Relatórios',
    type: 'link',
    icon: 'assignment',
    permissao: 'todos'
  }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
