import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { JwtHelperService } from "@auth0/angular-jwt";
import { map } from "rxjs/operators";

const LOGIN_URL: string = "https://gr.keymax.com.br:3001/auth";
// const LOGIN_URL: string = 'http://localhost:3000/auth';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  usuarioAtual: any;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem("token");
    if (token) {
      let jwt = new JwtHelperService();
      this.usuarioAtual = jwt.decodeToken(token);
    }
  }

  login(credentials) {
    return this.http.post(LOGIN_URL, credentials).pipe(
      map((response: any) => {
        if (response && response.token) {
          let jwt = new JwtHelperService();
          localStorage.setItem("token", response.token);
          this.usuarioAtual = jwt.decodeToken(localStorage.getItem("token"));

          return true;
        } else {
          return false;
        }
      })
    );
  }

  logout() {
    localStorage.removeItem("token");
    this.usuarioAtual = null;
  }

  isLoggedIn() {
    let token = new JwtHelperService();
    return token.isTokenExpired(localStorage.getItem("token"));
  }
}
