import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

const URL_RELATORIOS_CONDOMINIO: string =
  "https://gr.keymax.com.br:3001/relatorio/cliente";
const URL_RELATORIOS_VIGILANTE: string =
  "https://gr.keymax.com.br:3001/relatorio/funcionario";

// const URL_RELATORIOS_CONDOMINIO: string = 'http://localhost:3000/relatorio/cliente';
// const URL_RELATORIOS_VIGILANTE: string = 'http://localhost:3000/relatorio/funcionario';

@Injectable({
  providedIn: "root",
})
export class RelatoriosService {
  constructor(private http: HttpClient) {}

  getRelatorioCliente(pageIndex, pageSize, dados) {
    return this.http.post<any>(
      `${URL_RELATORIOS_CONDOMINIO}?page=${pageIndex}&size=${pageSize}`,
      dados
    );
  }

  getRelatorioFuncionario(pageIndex, pageSize, dados) {
    return this.http.post<any>(
      `${URL_RELATORIOS_VIGILANTE}?page=${pageIndex}&size=${pageSize}`,
      dados
    );
  }

  getRelatorioCSV(dados) {
    if (dados.tipo === 0)
      return this.http.post<any>(`${URL_RELATORIOS_CONDOMINIO}?page=0`, dados);
    else
      return this.http.post<any>(`${URL_RELATORIOS_VIGILANTE}?page=0`, dados);
  }
}
