import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { FuncionarioDto } from "../dto/funcionario.dto";

const URL_FUNCIONARIOS: string =
  "https://gr.keymax.com.br:3001/usuario/funcionarios";
const URL_FUNCIONARIO: string = "https://gr.keymax.com.br:3001/usuario";
const URL_ALL_FUNCIONARIOS: string =
  "https://gr.keymax.com.br:3001/usuario/allFuncionarios";
const URL_CREATE_FUNCIONARIOS: string =
  "https://gr.keymax.com.br:3001/usuario/novoFuncionario";

// const URL_FUNCIONARIOS: string = 'http://localhost:3000/usuario/funcionarios';
// const URL_FUNCIONARIO: string = 'http://localhost:3000/usuario';
// const URL_ALL_FUNCIONARIOS: string = 'http://localhost:3000/usuario/allFuncionarios';
// const URL_CREATE_FUNCIONARIOS: string = 'http://localhost:3000/usuario/novoFuncionario';

@Injectable({
  providedIn: "root",
})
export class FuncionarioService {
  constructor(private http: HttpClient) {}

  getFuncionario(idFuncionario: number) {
    return this.http.get<any>(`${URL_FUNCIONARIO}/${idFuncionario}`);
  }

  getFuncionarios(pageIndex, pageSize) {
    console.log(pageIndex);
    return this.http.get<any>(
      `${URL_FUNCIONARIOS}?page=${pageIndex}&size=${pageSize}`
    );
  }

  getAllFuncionarios() {
    return this.http.get<any>(URL_ALL_FUNCIONARIOS);
  }

  createFuncionario(dadosFuncionario: FuncionarioDto) {
    return this.http.post<any>(URL_CREATE_FUNCIONARIOS, dadosFuncionario);
  }

  updateFuncionario(dadosFuncionario: FuncionarioDto) {
    return this.http.put<any>(URL_FUNCIONARIO, dadosFuncionario);
  }
}
