import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(protected router: Router, protected authService: AuthService) { }

  canActivate() {

    console.log(this.authService.usuarioAtual);

    if (this.authService.usuarioAtual.tipo === 'admin') {
      return true;
    } else {
      this.router.navigate(['/relatorios']);
      return false;
    }

  }

}
