import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  termsAccepted = localStorage.getItem("termsAccepted");

  constructor(private router: Router) {}

  openTerms() {
    localStorage.setItem("termsAccepted", "true");
    this.termsAccepted = localStorage.getItem("termsAccepted");
    this.router.navigateByUrl("terms-and-conditions");
  }
}
